import React from 'react';

const SVGIcon = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5954 3.26496L21.2354 8.54496C21.5954 9.27996 22.5554 9.98496 23.3654 10.12L28.1504 10.915C31.2104 11.425 31.9304 13.645 29.7254 15.835L26.0054 19.555C25.3754 20.185 25.0304 21.4 25.2254 22.27L26.2904 26.875C27.1304 30.52 25.1954 31.93 21.9704 30.025L17.4854 27.37C16.6754 26.89 15.3404 26.89 14.5154 27.37L10.0304 30.025C6.82035 31.93 4.87035 30.505 5.71035 26.875L6.77535 22.27C6.97035 21.4 6.62535 20.185 5.99535 19.555L2.27535 15.835C0.0853517 13.645 0.790352 11.425 3.85035 10.915L8.63535 10.12C9.43035 9.98496 10.3904 9.27996 10.7504 8.54496L13.3904 3.26496C14.8304 0.399961 17.1704 0.399961 18.5954 3.26496Z"
      stroke="#5B66EA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const SVGStarIcon = (props) => {
  return (
    <div className="anticon">
      <SVGIcon {...props} />
    </div>
  );
};

export default SVGStarIcon;
