import React from "react";

const SVGIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.999 2.00195C6.477 2.00195 2 6.47895 2 12.001C2 16.991 5.656 21.127 10.437 21.88V14.892H7.897V12.001H10.437V9.79795C10.437 7.28995 11.93 5.90695 14.213 5.90695C15.307 5.90695 16.453 6.10195 16.453 6.10195V8.56095H15.189C13.949 8.56095 13.561 9.33295 13.561 10.124V11.999H16.332L15.889 14.89H13.561V21.878C18.342 21.129 21.998 16.992 21.998 12.001C21.998 6.47895 17.521 2.00195 11.999 2.00195Z"
      fill="white"
    />
  </svg>
);

const SVGNPSFacebook = (props) => {
  return (
    <div className="anticon">
      <SVGIcon {...props} />
    </div>
  );
};

export default SVGNPSFacebook;
