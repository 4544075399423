import React from "react";

const SVGIcon = (props) => (
  <svg
    width="410"
    height="183"
    viewBox="0 0 410 183"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.05"
      d="M410 -21C410 91.6661 318.218 183 205 183C91.7816 183 0 91.6661 0 -21C0 -133.666 91.7816 -225 205 -225C318.218 -225 410 -133.666 410 -21Z"
      fill="#6043CB"
    />
  </svg>
);

const SVGNPSScorePageCircle = (props) => {
  return (
    <div className="anticon">
      <SVGIcon {...props} />
    </div>
  );
};

export default SVGNPSScorePageCircle;
