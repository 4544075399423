import React from "react";

const SVGIcon = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="6" fill="white" />
    <path
      d="M25.8055 14.0415H25V14H16V18H21.6515C20.827 20.3285 18.6115 22 16 22C12.6865 22 10 19.3135 10 16C10 12.6865 12.6865 10 16 10C17.5295 10 18.921 10.577 19.9805 11.5195L22.809 8.691C21.023 7.0265 18.634 6 16 6C10.4775 6 6 10.4775 6 16C6 21.5225 10.4775 26 16 26C21.5225 26 26 21.5225 26 16C26 15.3295 25.931 14.675 25.8055 14.0415Z"
      fill="#FFC107"
    />
    <path
      d="M7.15625 11.3455L10.4417 13.755C11.3307 11.554 13.4838 10 16.0033 10C17.5328 10 18.9243 10.577 19.9838 11.5195L22.8123 8.691C21.0262 7.0265 18.6372 6 16.0033 6C12.1623 6 8.83125 8.1685 7.15625 11.3455Z"
      fill="#FF3D00"
    />
    <path
      d="M15.9963 26.0003C18.5793 26.0003 20.9263 25.0118 22.7008 23.4043L19.6058 20.7853C18.568 21.5745 17.3 22.0014 15.9963 22.0003C13.3953 22.0003 11.1867 20.3418 10.3547 18.0273L7.09375 20.5398C8.74875 23.7783 12.1098 26.0003 15.9963 26.0003Z"
      fill="#4CAF50"
    />
    <path
      d="M25.8055 14.0415H25V14H16V18H21.6515C21.2571 19.1082 20.5467 20.0766 19.608 20.7855L19.6095 20.7845L22.7045 23.4035C22.4855 23.6025 26 21 26 16C26 15.3295 25.931 14.675 25.8055 14.0415Z"
      fill="#1976D2"
    />
  </svg>
);

const SVGNPSGoogle = (props) => {
  return (
    <div className="anticon">
      <SVGIcon {...props} />
    </div>
  );
};

export default SVGNPSGoogle;
