import React, { forwardRef, useImperativeHandle, useRef } from "react";
import "./InputTextarea.css";
import SVGClearIcon from "../../icons/SVGs/SVGClearIcon";

const deviceSize = ["mobile", "tabletPortrait", "tablet"];

const InputTextarea = forwardRef((props, ref) => {
  const inputAreaRef = useRef();

  useImperativeHandle(ref, () => ({
    autoFocus() {
      if (!deviceSize.includes(props.screenSize)) {
        inputAreaRef.current && inputAreaRef.current.focus();
      }
    },
  }));

  return (
    <div className="mat-textarea-wrapper">
      <textarea
        className={`mat-textarea mat-textarea-secondary ${
          props.className || ""
        }`}
        placeholder={props.label || props.placeholder}
        rows={props.rows}
        cols={props.cols}
        disabled={props.disabled}
        onChange={props.onChange}
        ref={inputAreaRef}
        value={props.value}
        onClick={props.onClick}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        required={props.required}
        size={props.size}
        readOnly={props.readOnly}
        maxLength={props.maxLength}
        onBlur={props.onBlur}
      />
      <SVGClearIcon onClick={props.onClear} />
    </div>
  );
});

InputTextarea.defaultProps = {
  placeholder: "Comment",
  rows: 3,
  cols: 50,
  size: "middle",
  required: false,
  autoComplete: "off",
};

export default InputTextarea;
