import React from "react";
import "./LoadingSpinner.css";

const LoadindSpinner = () => {
  return (
    <div className="loading-spinner-wrapper">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadindSpinner;
