import React from "react";

const SVGIcon = (props) => (
  <svg
    width="372"
    height="303"
    viewBox="0 0 372 303"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M-146.415 79.0217C203.358 -152.131 282.824 211.067 204.783 232.631C126.741 254.195 101.129 130.073 210.879 143.723C320.63 157.372 415.39 270.651 346.644 417.428"
      stroke="#6043CB"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="15.79 15.79"
    />
  </svg>
);

const SVGNPSScorePageDashed = (props) => {
  return (
    <div className="anticon">
      <SVGIcon {...props} />
    </div>
  );
};

export default SVGNPSScorePageDashed;
