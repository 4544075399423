import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import feedbackActions from '../../actions/feedbackActions';
import { notifyError } from '../../utils/Notify';
import { getText, getTextServerError } from '../../lang';
import LoadindSpinner from '../../components/LoadingSpinner';
import PageFull from '../../components/PageFull';
import NpsRatePage from './NpsRatePage';
import NpsLeaveReviewPage from './NpsLeaveReviewPage';
import NpsFeedbackPage from './NpsFeedbackPage';
import './Nps.css';

const convertNumberTo5PointSystemValue = (
  is5StarReviewEnabled = false,
  value = 1
) => {
  if (is5StarReviewEnabled) {
    return Math.ceil((value || 1) / 2);
  }
  return value;
};

const NPS = () => {
  const { _id } = useParams();
  const navigate = useNavigate();

  const [lang, setLang] = useState('en');
  const [location, setLocation] = useState('');
  const [selectedRate, setSelectedRate] = useState('');
  const [step, setStep] = useState('');
  const [urls, setUrls] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getFeedbackData = useCallback(
    async (isMounted) => {
      if (_id) {
        setIsLoading(true);
        const result = await feedbackActions.getInvitation(_id);
        if (result.success) {
          if (isMounted) {
            const data = result.data;
            const customer = data.customer;
            setLang(customer.language);
            setLocation(data);

            if (data.vote_value) {
              if (
                data.vote_value >=
                convertNumberTo5PointSystemValue(
                  data.location.is5StarReviewEnabled,
                  data.location?.minRedirectScore
                )
              ) {
                const result = await feedbackActions.sendPositive(
                  _id
                );
                if (result.success) {
                  setStep(1);
                  setUrls(result.data.urls);
                } else {
                  notifyError(getTextServerError(result.errMsg));
                }
              } else {
                setStep(2);
              }
            } else {
              setStep(0);
            }
          }
        } else {
          notifyError(
            result.errMsg
              ? result.errMsg.startsWith('child')
                ? getText('ERR_GENERAL', lang)
                : result.errMsg
              : getText('ERR_GENERAL', lang)
          );
          navigate('/error');
        }
        setIsLoading(false);
      }
    },
    [_id, lang, navigate]
  );

  useEffect(() => {
    let isMounted = true;
    getFeedbackData(isMounted);
    return () => {
      isMounted = false;
    };
  }, [getFeedbackData]);

  const handleSelectLink = async (obj) => {
    await feedbackActions.selectLink(_id, obj.weight);
    if (obj.url) {
      window.location.href = obj.url;
    } else {
      notifyError(getText('ERR_GENERAL', lang));
    }
  };

  return isLoading ? (
    <LoadindSpinner />
  ) : (
    <PageFull
      className={`feedback-page-wrapper ${
        step === 1 ? 'positive-feedback-page' : ''
      }`}
    >
      {step === 0 && (
        <NpsRatePage
          lang={lang}
          location={location}
          selectedRate={selectedRate}
          onClick={async (index) => {
            setSelectedRate(index);
            let vote = index + 1;
            const resultVote = await feedbackActions.sendVote(
              _id,
              vote
            );
            if (resultVote.success) {
              if (
                vote >=
                convertNumberTo5PointSystemValue(
                  location.location?.is5StarReviewEnabled,
                  location?.location?.minRedirectScore
                )
              ) {
                const result = await feedbackActions.sendPositive(
                  _id
                );
                if (result.success) {
                  setStep(1);
                  setUrls(result.data.urls);
                } else {
                  notifyError(getTextServerError(resultVote.errMsg));
                }
              } else {
                setStep(2);
              }
            } else {
              notifyError(getTextServerError(resultVote.errMsg));
            }
          }}
        />
      )}
      {step === 1 && (
        <NpsLeaveReviewPage
          urls={urls}
          handleSelectLink={handleSelectLink}
          location={location}
        />
      )}
      {step === 2 && (
        <NpsFeedbackPage
          id={_id}
          lang={lang}
          onClickBack={() => {
            setStep(0);
            getFeedbackData();
          }}
          onSendButton={() => {
            setStep(3);
          }}
          location={location}
        />
      )}
      {step === 3 && (
        <>
          <div className="nps-feedback-title strong">
            {getText('TITLE_FEEDBACK_NEG_CONFIRM', lang)}
          </div>
          <div className="nps-feedback-title">
            {getText('TEXT_FEEDBACK_NEG_HELP_CONFIRM', lang)}
          </div>
        </>
      )}
    </PageFull>
  );
};

export default NPS;
