import React from "react";
import "./PageFull.css";

const PageFull = ({ children, className, style }) => {
  return (
    <div style={style} className={`my-page-full ${className || ""}`}>
      {children}
    </div>
  );
};

export default PageFull;
