import React from "react";
import SVGNPSScorePageCircle from "../../icons/SVGs/SVGNPSScorePageCircle";
import SVGNPSScorePageDashed from "../../icons/SVGs/SVGNPSScorePageDashed";
import SVGNPSScorePageMatador from "../../icons/SVGs/SVGNPSScorePageMatador";
import SVGNPSScorePageCircleOne from "../../icons/SVGs/SVGNPSScorePageCircleOne";
import SVGNPSScorePageCircleTwo from "../../icons/SVGs/SVGNPSScorePageCircleTwo";
import SVGNPSGoogle from "../../icons/SVGs/SVGNPSGoogle";
import SVGNPSFacebook from "../../icons/SVGs/SVGNPSFacebook";
import SVGNPSYelp from "../../icons/SVGs/SVGNPSYelp";
import MatButton from "../../components/MatButton";

const NpsLeaveReviewPage = (props) => {
  const { urls, handleSelectLink, location } = props;

  const getUrlStyles = (url) => {
    switch (url) {
      case "Google":
        return { icon: <SVGNPSGoogle />, color: "#4285F4" };
      case "Facebook":
        return { icon: <SVGNPSFacebook />, color: "#4C67A8" };
      case "Yelp":
        return { icon: <SVGNPSYelp />, color: "#d32323" };
      default:
        break;
    }
  };

  return (
    <>
      <div className="nps-score-abs nps-score-abs-icon-left-top">
        <SVGNPSScorePageCircle />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-left-bottom">
        <SVGNPSScorePageDashed />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-right-top">
        <SVGNPSScorePageMatador />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-left-center">
        <SVGNPSScorePageCircleOne />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-right-center">
        <SVGNPSScorePageCircleTwo />
      </div>
      <div className="nps-feedback-title strong">
        {location.reviewInvitePositivePage}
      </div>
      {urls &&
        urls.map((url, index) => {
          return (
            <div key={index} className="link-button-wrapper">
              <MatButton
                className="list-url-button"
                style={{
                  width: "335px",
                  height: "60px",
                  background: getUrlStyles(url.name)
                    ? getUrlStyles(url.name).color
                    : url.color,
                }}
                buttonText={
                  <>
                    {getUrlStyles(url.name) && getUrlStyles(url.name).icon}{" "}
                    {url.name}
                  </>
                }
                onClick={() => {
                  handleSelectLink && handleSelectLink(url);
                }}
              />
            </div>
          );
        })}
    </>
  );
};

export default NpsLeaveReviewPage;
