import React from "react";

const SVGIcon = (props) => (
  <svg
    width="339"
    height="963"
    viewBox="0 0 339 963"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.06"
      d="M800.492 5.28336C797.893 -0.00789076 790.096 -0.00789034 784.898 2.63774C782.299 2.63774 782.299 5.28336 782.299 7.92899C779.7 10.5746 779.7 13.2202 782.299 15.8659C813.487 111.108 764.106 214.288 673.141 246.035C654.948 251.327 636.755 256.618 615.963 256.618L228.712 256.618C129.95 253.972 49.381 171.958 51.98 71.424C51.98 52.9046 54.579 34.3852 62.376 15.8659C62.376 13.2202 62.376 10.5746 62.376 7.92899C57.178 -0.00788993 51.98 -2.65352 46.782 -0.00789022C44.183 -0.00789022 44.183 2.63774 41.584 2.63774C15.594 47.6134 0 95.2346 0 145.502C0 253.972 67.574 349.215 168.935 383.608C70.173 513.244 80.569 698.437 194.925 814.845L319.677 941.835C332.672 955.063 350.865 963 366.459 963H473.018C491.211 963 509.404 955.063 519.8 941.835L644.552 814.845C758.908 698.437 769.304 513.244 670.542 383.608C769.304 349.215 836.878 253.972 836.878 145.502C842.076 95.2346 829.081 47.6134 800.492 5.28336ZM553.587 711.666L410.642 859.82V769.869C366.459 767.224 322.276 748.704 291.088 716.957C220.915 642.879 220.915 521.18 293.687 449.749C366.459 378.317 483.414 378.317 553.587 452.394C587.374 486.787 605.567 534.409 605.567 582.03C605.567 632.297 587.374 677.272 553.587 711.666Z"
      fill="#6043CB"
    />
  </svg>
);

const SVGNPSScorePageMatador = (props) => {
  return (
    <div className="anticon">
      <SVGIcon {...props} />
    </div>
  );
};

export default SVGNPSScorePageMatador;
