import {
  fetchFromUrlGETAsync,
  fetchFromUrlPOSTAsync,
} from "../utils/urlHelper";

const urlFeedback = "/feedback";

const fs = {
  getInvitation: async (itemId) => {
    const result = await fetchFromUrlGETAsync(urlFeedback + "/" + itemId, []);
    return result;
  },

  sendPositive: async (itemId) => {
    const result = await fetchFromUrlPOSTAsync(
      urlFeedback + "/" + itemId + "/yes",
      []
    );
    return result;
  },

  sendNegative: async (itemId, comments) => {
    const urlParams = [{ key: "comments", value: comments }];
    const result = await fetchFromUrlPOSTAsync(
      urlFeedback + "/" + itemId + "/no",
      urlParams
    );
    return result;
  },

  sendVote: async (itemId, vote) => {
    const urlParams = [{ key: "vote", value: vote }];
    const result = await fetchFromUrlPOSTAsync(
      urlFeedback + "/" + itemId + "/vote",
      urlParams
    );
    return result;
  },

  selectLink: async (itemId, weight) => {
    const urlParams = [{ key: "weight", value: weight }];
    const result = await fetchFromUrlPOSTAsync(
      urlFeedback + "/" + itemId,
      urlParams
    );
    return result;
  },
};

const loc = {};

const feedbackActions = Object.assign(fs, loc);

export default feedbackActions;
