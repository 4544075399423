const texts = {
  ERR_GENERAL: "An error happened. Please contact an administrator.",
  ERR_SERVER_NOT_CONNECTED: "Connection error.",
  WORD_NOT_LIKELY: "Not Likely",
  WORD_VERY_LIKELY: "Very Likely",
  TITLE_FEEDBACK_NEG: "Thanks for your feedback",
  TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER: "Let us know what happened",
  ACTION_BACK: "Back",
  ACTION_SEND: "Send",
  TITLE_FEEDBACK_NEG_CONFIRM: "Thank you for your feedback",
  TEXT_FEEDBACK_NEG_HELP_CONFIRM:
    "We are continuously working on improving our customer experience. Your feedback matters to us.",
  NTF_SUCCESS_SEND_FEEDBACK: "Feedback sent with success.",
  ERR_NO_FEEDBACK: "Please fill out the text field.",
};

export default texts;
