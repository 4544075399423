import React from "react";
import "./MatRow.css";

const MatRow = (props) => {
  return (
    <div
      style={props.style}
      className={`row-div-container
        ${props.flexStartCol ? "col-flex-start" : ""}
        ${props.flexEndCol ? "col-flex-end" : ""}
        ${props.flexStart ? "row-flex-start" : ""}
        ${props.flexEnd ? "row-flex-end" : ""}
        ${props.spaceBetween ? "space-between" : ""} ${props.className || ""}
      `}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default MatRow;
