import React, { useState } from "react";
import { getText } from "../../lang";
import feedbackActions from "../../actions/feedbackActions";
import { notifyError, notifySuccess } from "../../utils/Notify";
import MatButton from "../../components/MatButton";
import MatRow from "../../components/MatRow";
import InputTextarea from "../../components/InputTextarea";

const NpsFeedbackPage = (props) => {
  const { lang, onClickBack, onSendButton, id, location } = props;

  const [value, setValue] = useState("");

  return (
    <>
      <div className="nps-feedback-title strong">
        {getText("TITLE_FEEDBACK_NEG", lang)}
      </div>
      <div className="nps-feedback-title">
        {location.reviewInviteNegativePage}
      </div>
      <InputTextarea
        placeholder={getText("TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER", lang)}
        minRows={4}
        maxRows={6}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        onClear={() => {
          setValue("");
        }}
      />
      <MatRow className="feedback-button-wrapper">
        <MatButton
          buttonText={getText("ACTION_BACK", lang)}
          size={"large"}
          typeButton="cancel"
          onClick={() => {
            onClickBack && onClickBack();
          }}
        />
        <MatButton
          buttonText={getText("ACTION_SEND", lang)}
          size={"large"}
          onClick={async () => {
            if (value) {
              const result = await feedbackActions.sendNegative(id, value);
              if (result.success) {
                notifySuccess(getText("NTF_SUCCESS_SEND_FEEDBACK", lang));
                onSendButton && onSendButton();
              }
            } else {
              notifyError(getText("ERR_NO_FEEDBACK", lang));
            }
          }}
        />
      </MatRow>
    </>
  );
};

export default NpsFeedbackPage;
