import { fetchFromUrlGETAsync } from "../utils/urlHelper";

const fs = {
  getShortUrl: async (hash) => {
    const { data } = await fetchFromUrlGETAsync(`/short/${hash}`);
    return data;
  },
};

const loc = {};

const appActions = Object.assign(fs, loc);

export default appActions;
