import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorPage from "../components/Error/ErrorPage";
import NPS from "../pages/nps";
import ShortLinkRedirect from "../pages/redirects/ShortLinkRedirect";

const AppRouter = (props) => {
  return (
    <Routes>
      <Route exact path="/error" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/error" />} />
      <Route path="/feedback/:_id" element={<NPS />} />
      <Route path="/s/:_id" element={<ShortLinkRedirect />} />
    </Routes>
  );
};

export default AppRouter;
