import React from "react";

const SVGIcon = (props) => (
  <svg
    width="105"
    height="104"
    viewBox="0 0 105 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.05"
      d="M105 52C105 80.7188 81.4949 104 52.5 104C23.5051 104 0 80.7188 0 52C0 23.2812 23.5051 0 52.5 0C81.4949 0 105 23.2812 105 52Z"
      fill="#6043CB"
    />
  </svg>
);

const SVGNPSScorePageCircleTwo = (props) => {
  return (
    <div className="anticon">
      <SVGIcon {...props} />
    </div>
  );
};

export default SVGNPSScorePageCircleTwo;
