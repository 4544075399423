import React from "react";
import "./MatButton.css";

const MatButton = (props) => {
  return (
    <button
      id={props.id}
      type={props.typeButton}
      onClick={props.onClick}
      loading={props.loading}
      icon={props.icon}
      style={{ borderRadius: props.radius, ...props.style }}
      className={`mat-btn ${
        props.typeButton ? `mat-btn-${props.typeButton}` : ""
      } ${props.size ? `mat-btn-${props.size}` : ""} ${props.className || ""}`}
      disabled={props.disabled}
      size={props.size}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      onTouchStart={props.onTouchStart}
      onTouchEnd={props.onTouchEnd}
    >
      <span> {props.buttonText || ""}</span>
    </button>
  );
};

MatButton.defaultProps = {
  text: "Save",
  typeButton: "primary",
  formName: "formSubmit",
};

export default MatButton;
