export function getText(textKey, lang) {
  const userLang = "en";
  if (!lang) {
    lang = userLang;
  }
  const langData = module.require(`./${lang}`).default;
  let str = langData[textKey];

  if (str) {
    return str;
  } else {
    return "<" + textKey + ">";
  }
}

export function getTextServerError(errMSG, param) {
  return errMSG ? errMSG : getText("ERR_GENERAL");
}
