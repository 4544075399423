const texts = {
  ERR_GENERAL:
    "Une erreur est survenue. S'il vous plaît contacter un administrateur.",
  ERR_SERVER_NOT_CONNECTED: "Connection error.",
  WORD_NOT_LIKELY: "Pas probable",
  WORD_VERY_LIKELY: "Très probable",
  TITLE_FEEDBACK_NEG: "Merci pour vos commentaires",
  TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER: "Dites-nous ce qui s'est passé",
  ACTION_BACK: "retour",
  ACTION_SEND: "envoyer",
  TITLE_FEEDBACK_NEG_CONFIRM: "Merci pour votre avis",
  TEXT_FEEDBACK_NEG_HELP_CONFIRM:
    "Nous travaillons continuellement à améliorer notre expérience client. Vos commentaires sont importants pour nous.",
  NTF_SUCCESS_SEND_FEEDBACK: "Commentaires envoyés avec succès.",
  ERR_NO_FEEDBACK: "S'il vous plaît remplir le champ de texte.",
};

export default texts;
