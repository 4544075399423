import { toast } from "react-toastify";

const config = {
  closeButton: true,
  closeOnClick: true,
  position: "top-right",
  autoClose: 3000,
};

export const notifySuccess = (text) => toast.success(text, config);

export const notifyError = (text) => toast.error(text, config);

export const notifywarning = (text) => toast.warning(text, config);

export const notifyText = (text) => toast(text, config);
