import React, { useMemo } from 'react';
import SVGNPSScorePageCircleOne from '../../icons/SVGs/SVGNPSScorePageCircleOne';
import SVGNPSScorePageCircle from '../../icons/SVGs/SVGNPSScorePageCircle';
import SVGNPSScorePageDashed from '../../icons/SVGs/SVGNPSScorePageDashed';
import SVGNPSScorePageMatador from '../../icons/SVGs/SVGNPSScorePageMatador';
import SVGNPSScorePageCircleTwo from '../../icons/SVGs/SVGNPSScorePageCircleTwo';
import { getText } from '../../lang';
import MatRow from '../../components/MatRow';
import SVGStarIcon from '../../icons/SVGs/SVGStarIcon';

const NpsRatePage = (props) => {
  const { lang, selectedRate, onClick, location } = props;

  const rateArray = useMemo(
    () =>
      location?.location?.is5StarReviewEnabled
        ? [1, 2, 3, 4, 5]
        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [location?.location?.is5StarReviewEnabled]
  );

  return (
    <>
      <div className="nps-score-abs nps-score-abs-icon-left-top">
        <SVGNPSScorePageCircle />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-left-bottom">
        <SVGNPSScorePageDashed />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-right-top">
        <SVGNPSScorePageMatador />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-left-center">
        <SVGNPSScorePageCircleOne />
      </div>
      <div className="nps-score-abs nps-score-abs-icon-right-center">
        <SVGNPSScorePageCircleTwo />
      </div>
      <div className="feedback-icon-wrapper">
        {location.location.friendlyName || location.location.name}
      </div>
      <div className="feedback-body-wrapper">
        <div className="feedback-body-title">
          {location.reviewInviteRatingPage}
        </div>
        <div className="feedback-nps-numbers-wrapper">
          <MatRow className="nps-number-item-wrapper">
            {rateArray.map((num, index) => {
              return Boolean(
                location?.location?.is5StarReviewEnabled
              ) ? (
                <div
                  key={index}
                  className={`number-item ${
                    (selectedRate || selectedRate === 0) &&
                    index <= selectedRate
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    onClick && onClick(index);
                  }}
                >
                  <SVGStarIcon />
                </div>
              ) : (
                <div
                  key={index}
                  className={`number-item ${
                    (selectedRate || selectedRate === 0) &&
                    index === selectedRate
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    onClick && onClick(index);
                  }}
                >
                  {num}
                </div>
              );
            })}
          </MatRow>
          <MatRow spaceBetween className="feeback-lucky-wrapper">
            <span>{getText('WORD_NOT_LIKELY', lang)}</span>
            <span>{getText('WORD_VERY_LIKELY', lang)}</span>
          </MatRow>
        </div>
      </div>
    </>
  );
};

export default NpsRatePage;
