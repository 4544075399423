import axios from "axios";
import { createBrowserHistory } from "history";
import _ from "lodash";
import { getText } from "../lang";

let prefix = "http";
let socketPrefix = "ws";
if (["PROD", "STAGE", "RELEASE"].includes(process.env.REACT_APP_ENV)) {
  prefix = "https";
  socketPrefix = "wss";
}
let BASE_URL = `${prefix}://${process.env.REACT_APP_DOMAIN}/api/v1`;
let BASE_URL_SCHEDULE = `${prefix}://${process.env.REACT_APP_LAMBDA_DOMAIN}/api/v2`;
let SOCKET_URL = `${socketPrefix}://${process.env.REACT_APP_SOCKET_DOMAIN}`;

export const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 30000;

export const siteUrl = window.location.origin;

export const history = createBrowserHistory({ forceRefresh: true });

export function redirect(path, state) {
  window.history.pushState(null, null, path);
  window.dispatchEvent(new Event("popstate"));
}

export function redirectPSS(path, search, state) {
  let oo = {
    pathname: path,
  };
  if (search) {
    oo.search = search;
  }
  if (state) {
    oo.state = state;
  }
  history.push(oo);
}

export function urlServer(path = "") {
  return BASE_URL + path;
}

export function urlServerV2(path = "") {
  return BASE_URL_SCHEDULE + path;
}

export function urlSocket(path = "") {
  return SOCKET_URL + path;
}

export function urlImgServer(path) {
  return "https://wingage.io/" + path;
}

export async function fetchFromUrlPOSTAsync(url, parameters) {
  return fetchAS("POST", url, parameters);
}

export async function fetchFromUrlGETAsync(
  url,
  parameters = [],
  increaseTimeOut = false
) {
  return await fetchAS("GET", url, parameters, increaseTimeOut);
}

export async function fetchFromUrlPUTAsync(url, parameters) {
  return await fetchAS("PUT", url, parameters);
}

export async function fetchFromUrlPATCHAsync(url, parameters) {
  return await fetchAS("PATCH", url, parameters);
}

export async function fetchFromUrlDELETEAsync(url, parameters) {
  return await fetchAS("DELETE", url, parameters);
}

export async function fetchAS(
  method,
  url,
  parameters = [],
  increaseTimeOut = false
) {
  const timeout = increaseTimeOut ? 10000 : 30000;
  let fetchUrl = urlServer(url);
  let toSend = {};
  parameters.forEach((par) => {
    toSend[par.key] = par.value;
  });

  let body = undefined;
  let params = undefined;

  if (
    method === "POST" ||
    method === "PUT" ||
    method === "PATCH" ||
    method === "DELETE"
  ) {
    body = JSON.stringify(toSend);
  } else if (method === "GET") {
    params = toSend;
  }

  const config = {
    url: fetchUrl,
    data: body,
    params,
    method,
    timeout: timeout,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Socket-Id": sessionStorage.getItem("socketId") || "",
    },
  };

  try {
    const response = await axiosInstance.request(config);
    const obj = {
      success: response && response.status >= 200 && response.status < 300,
    };
    const data = response && response.data;

    if (obj.success) {
      obj.data = data;
    } else {
      obj.errCode = data && data.code;
      obj.errMsg = data && data.message;
      if (data && data.errors) {
        obj.errors = data.errors;
      }
    }

    if (obj.errCode === 401) {
      redirect("/login");
    }
    return obj;
  } catch (err) {
    if (_.get(err.response, "data.code") === 401) {
      redirect("/login");
    }
    return {
      success: false,
      errMsg: _.get(
        err.response,
        "data.message",
        err.message,
        getText("ERR_SERVER_NOT_CONNECTED")
      ),
      errCode: err.code || null,
    };
  }
}
