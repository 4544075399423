const texts = {
  ERR_GENERAL: "An error happened. Please contact an administrator.",
  ERR_SERVER_NOT_CONNECTED: "Connection error.",
  WORD_NOT_LIKELY: "No es probable",
  WORD_VERY_LIKELY: "Muy probable",
  TITLE_FEEDBACK_NEG: "Gracias por tus comentarios",
  TEXT_FEEDBACK_NEG_INPUT_PLACEHOLDER: "Cuéntanos qué pasó",
  ACTION_BACK: "Regresar",
  ACTION_SEND: "Enviar",
  TITLE_FEEDBACK_NEG_CONFIRM: "Gracias por tus comentarios",
  TEXT_FEEDBACK_NEG_HELP_CONFIRM:
    "Estamos trabajando continuamente para mejorar la experiencia de nuestros clientes. Su opinión nos importa.",
  NTF_SUCCESS_SEND_FEEDBACK: "Retroalimentación enviada con éxito.",
  ERR_NO_FEEDBACK: "Por favor, introduzca el texto.",
};

export default texts;
